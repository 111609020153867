<template>
  <div class="top-menu">
    <div class="left">
      <a-icon class="icon" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="changeCollapsed" />
      <a-icon class="icon" type="reload" @click="refresh" />
      <!-- <el-breadcrumb separator="/">
              <el-breadcrumb-item v-for=" item in bread" :key="item.path" :to="{ path:item.path }">{{item.name}}</el-breadcrumb-item>

            </el-breadcrumb> -->
    </div>
    <div class="right">
      <!-- <span>欢迎您：{{ userInfo.account }}</span>
      <div class="logout" @click="logout">
        <a-icon class="icon" type="logout" />
        退出登录
      </div> -->
      <el-dropdown style="height: 40px">
        <span class="el-dropdown-link">
          <span>
            <span>
              <!-- <img style="width: 24px; height: 24px" :src="userInfo.avatar" alt="" /> -->
            </span>
            <span style="cursor: pointer"> {{ userInfo?.account }}</span>
            <!-- <span> {{ userInfo.contact_phone }}</span> -->
          </span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="updatePassword"><a-icon class="icon" type="lock" />修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="logout"> <a-icon class="icon" type="logout" />退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!--  -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="700px">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="新密码" prop="password" :rules="rules">
          <el-input
            style="width: 400px"
            clearable
            placeholder="请输入密码"
            v-model="formData.password"
            type="password"
            size="small"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="updatePwd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { updatePwdAPI } from './api'
export default {
  name: 'TopMenu',
  model: {
    prop: 'collapsed'
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      userName: '',
      dialogVisible: false,
      formData: {
        password: ''
      },
      rules: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
    }
  },

  created() {
    this.userName = localStorage.getItem('userName')
  },

  computed: {
    ...mapState({ userInfo: state => state.user.userInfo }),
    bread() {
      let breadList = []
      breadList = this.$route.matched.map(item => {
        return {
          path: item.path,
          name: item.meta.name
        }
      })
      return breadList
    }
  },

  methods: {
    changeCollapsed() {
      this.$emit('input', !this.collapsed)
      this.$bus.$emit('showLogo')
    },

    refresh() {
      history.go(0)
    },

    logout() {
      //   const res = this.$store.dispatch('LogOut')
      //   console.log(res)
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push('/login')
        this.$store.dispatch('tagsView/delAllViews')
      })
    },
    updatePassword() {
      this.formData.password = ''
      this.dialogVisible = true
    },
    updatePwd() {
      this.$refs.form.validate(valid => {
        if (valid) {
          updatePwdAPI(this.formData).then(() => {
            this.dialogVisible = false
            this.$store.dispatch('LogOut').then(() => {
              this.$router.push('/login')
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;

  .icon {
    margin-right: 20px;
    font-size: 16px;
  }

  .right {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 20px;
      margin-right: 0;
    }

    .logout {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .left {
    display: flex;
    align-items: center;
  }
}
</style>
