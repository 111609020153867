const systemManageRouter = {
  path: '/systemManage',
  name: 'SystemManage',
  redirect: '/systemManage/dictManage/list',
  component: () => import('@/views/systemManage/index.vue'),
  meta: { name: '基础配置', icon: 'el-icon-setting' },
  children: [
    // {
    //   path: '/systemManage/dictManage/list',
    //   name: 'DictManageList',
    //   component: () => import('@/views/systemManage/dictManage/index.vue'),
    //   meta: { name: '字典管理' }
    // },

    // {
    //   path: '/systemManage/dictValueManage',
    //   name: 'DictValueManage',
    //   component: () => import('@/views/systemManage/dictManage/list-dict-value.vue'),
    //   meta: { hidden: true, name: '字典值管理' }
    // },

    {
      path: '/systemManage/config/list',
      name: 'ConfigList',
      component: () => import('@/views/systemManage/config/list.vue'),
      meta: { name: '网站设置' }
    },
    // {
    //   path: '/systemManage/contact-config/list',
    //   name: 'ContactConfig',
    //   component: () => import('@/views/systemManage/contact-config/list.vue'),
    //   meta: { name: '联系方式' }
    // },

    {
      path: '/systemManage/vipProduct/list',
      name: 'VipProductList',
      component: () => import('@/views/vipProduct/list.vue'),
      meta: {name: 'vip产品'}
    },

    // 公告管理
    {
      path: '/systemManage/notice/list',
      name: 'NoticeList',
      component: () => import('@/views/notice/list.vue'),
      meta: {name: '公告管理'}
    },
    {
      path: '/systemManage/notice/add',
      name: 'NoticeAdd',
      component: () => import('@/views/notice/update.vue'),
      meta: {
        name: '新增公告',
        hidden: true,
        activeMenu: '/notice/list'
      }
    },
    {
      path: '/systemManage/notice/edit/:id?',
      name: 'NoticeEdit',
      component: () => import('@/views/notice/update.vue'),
      meta: {
        name: '编辑公告',
        hidden: true,
        activeMenu: '/notice/list'
      }
    },

    // 友情链接
    {
      path: '/systemManage/friendlink/list',
      name: 'FriendlinkList',
      component: () => import('@/views/friendlink/list.vue'),
      meta: {name: '友情链接管理'}
    },
    {
      path: '/systemManage/friendlink/add',
      name: 'FriendlinkAdd',
      component: () => import('@/views/friendlink/update.vue'),
      meta: {
        name: '新增友情链接',
        hidden: true,
        activeMenu: '/friendlink/list'
      }
    },
    {
      path: '/systemManage/friendlink/edit/:id?',
      name: 'FriendlinkEdit',
      component: () => import('@/views/friendlink/update.vue'),
      meta: {
        name: '编辑友情链接',
        hidden: true,
        activeMenu: '/friendlink/list'
      }
    },
    {
      path: '/systemManage/advantage-config/list',
      name: 'AdvantageConfig',
      component: () => import('@/views/systemManage/advantage-config/list.vue'),
      meta: { name: '我的优势' }
    },
    // {
    //   path: '/systemManage/marketingbanner-config/list',
    //   name: 'marketingBannerConfig',
    //   component: () =>
    //     import('@/views/systemManage/marketingbanner-config/list.vue'),
    //   meta: { name: '营销图片配置' }
    // },
    {
      path: '/systemManage/banner/list',
      name: 'BannerList',
      component: () => import('@/views/systemManage/banner/list.vue'),
      meta: { name: 'banner设置' }
    },
    {
      path: '/systemManage/banner/add',
      name: 'BannerAdd',
      component: () => import('@/views/systemManage/banner/addEdit.vue'),
      meta: {
        name: '新增banner',
        hidden: true,
        activeMenu: '/systemManage/banner/list'
      }
    },
    {
      path: '/systemManage/banner/edit/:id?',
      name: 'BannerEdit',
      component: () => import('@/views/systemManage/banner/addEdit.vue'),
      meta: {
        name: '编辑banner',
        hidden: true,
        activeMenu: '/systemManage/banner/list'
      }
    },
    {
      path: '/systemManage/agreement/list',
      name: 'AgreementList',
      component: () => import('@/views/systemManage/agreement/list.vue'),
      meta: { name: '协议设置' }
    }
    // {
    //   path: '/systemManage/share/list',
    //   name: 'shareList',
    //   component: () => import('@/views/systemManage/share/list.vue'),
    //   meta: { name: '分享设置' }
    // },
    // {
    //   path: '/systemManage/mini/settings',
    //   name: 'MiniSettings',
    //   component: () => import('@/views/systemManage/mini-setting/list.vue'),
    //   meta: { name: '小程序设置' }
    // }
    // {
    //   path: '/systemManage/notice/list',
    //   name: 'NoticeList',
    //   component: () => import('@/views/systemManage/notice/index.vue'),
    //   meta: { name: '通知公告' }
    // },
    // {
    //   path: '/systemManage/notice/add',
    //   name: 'NoticeAdd',
    //   component: () => import('@/views/systemManage/notice/addEdit.vue'),
    //   meta: { name: '新增', hidden: true, activeMenu: '/systemManage/notice/list' }
    // },
    // {
    //   path: '/systemManage/notice/edit/:id?',
    //   name: 'NoticeEdit',
    //   component: () => import('@/views/systemManage/notice/addEdit.vue'),
    //   meta: { name: '编辑', hidden: true, activeMenu: '/systemManage/notice/list' }
    // }
  ]
}

export default systemManageRouter
