import Vue from 'vue'
import App from './App.vue'
import { router } from './router/index'
import store from '@/store/index'
import '@/styles/index.scss' // global css
import '@/styles/ruoyi.scss'
import '@/styles/animate.min.css'
import '@/plugins/index.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import registerCompenents from '@/plugins/register-components.js'

registerCompenents()
Vue.use(ElementUI, { zIndex: 3000 })
ElementUI.Dialog.props.closeOnClickModal.default = false

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  router,
  store
}).$mount('#app')
